export const localhostBaseUrl = 'https://universalportal-sso.qacitizensbank.com;';
export const assetPrefix = "/footer/";
export const assetURL = `${localhostBaseUrl}${assetPrefix}`;

export const environment = {
  production: false,
  isQA: false,
  isQA2: false,
  isSIT: false,
  isDEV: true,

  footer: {
    1: { type: 'link', text: 'Security', href: 'https://citizensbank-commercial--commops.sandbox.my.site.com/CommercialDigitalGateway/s/article/Security' },
    2: { type: 'link', text: 'Privacy Rights', href: 'https://www.citizensbank.com/account-safeguards/privacy.aspx' },
    3: { type: 'link', text: 'Terms of Use', href: 'https://citizensbank-commercial--commops.sandbox.my.site.com/CommercialDigitalGateway/s/article/Terms-of-Use' },
    4: { type: 'link', text: 'FDIC Coverage', href: 'https://www.fdic.gov/' },
    5: { type: 'link', text: 'Site Map', href: 'https://citizensbank-commercial--commops.sandbox.my.site.com/CommercialDigitalGateway/s/sitemap' },
    6: { type: 'link', text: 'Citizens Commercial', href: 'https://www.citizensbank.com/corporate-finance/default.aspx' }
  },
  footerCopyright: 'Citizens Financial Group, Inc. All rights reserved. Citizens Commercial Banking is a brand name of Citizens Bank, N.A. Member FDIC.',
  footerDisclaimer: 'Disclaimer: Cash Flow Forecasting provides information for informational purposes only, subject to our terms and conditions. We do not provide financial, accounting, tax, investment, or other professional advice.',
  footerLinksCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/footerLinks.json',

  footerLinksJson: {
    "footerCompanyLinks": [
        {
            "name": "About Us",
            "url": "https://www.citizensbank.com/about-us/overview.aspx"
        },
        {
            "name": "Career",
            "url": "https://jobs.citizensbank.com/?utm_source=citizensbank.com&utm_medium=referral&utm_campaign=careersite"
        },
        {
            "name": "Community",
            "url": "https://www.citizensbank.com/about-us/community.aspx"
        }
    ],
    "footerResourcesLinks": [
        {
            "name": "Branch Locator",
            "url": "https://www.citizensbank.com/customer-service/branch-locator.aspx?"
        }
    ],
    "footerBottomLinks": [
        {
            "name": "Security",
            "url": "https://client.qacitizensbank.com/s/article/Security"
        },
        {
            "name": "Privacy Policy",
            "url": "https://client.qacitizensbank.com/s/article/Privacy-Policy"
        },
        {
            "name": "Terms of Use",
            "url": "https://client.qacitizensbank.com/s/article/Terms-of-Use"
        },
        {
            "name": "FDIC Coverage",
            "url": "https://www.fdic.gov/"
        },
        {
            "name": "Site Map",
            "url": "https://client.qacitizensbank.com/s/sitemap"
        },
        {
            "name": "Citizens Commercial",
            "url": "https://www.citizensbank.com/corporate-finance/overview.aspx"
        }
    ]
  }

};

