import { Component, Input, NgZone, OnInit } from '@angular/core';
import { sharedState } from '@citizens/mfe-shared-state';
import { ApiCallServiceService } from 'src/app/services/api-call-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public footer: any = environment.footer;
  public copyright: any = environment.footerCopyright;
  public disclaimer: any = environment.footerDisclaimer;

  public year = new Date().getFullYear();
  @Input() isDashboard = false;
  public butlerApp = false;
  public bgColor = '#dad9d7';
  public textAlign: string = '';
  public isSMBUser = false;
  public footerCompanyLinks: any;
  public footerResourcesLinks: any;
  public footerBottomLinks: any;
  footerBottomFirstLinks: any;
  footerBottomSecondLinks: any;
  isLoginRoute: boolean = true;
  flagBs: any;

  constructor(
    private apiCallService: ApiCallServiceService, private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    sharedState.getFlagBS('login').subscribe((response) => {
      this.ngZone.run(()=> {
        this.isLoginRoute = (response === true || response === 'true');
      })
    })

    this.checkForSmbUser()
    
    this.apiCallService.getFooterLinksFromCDN().subscribe((res: any) => {
      this.footerCompanyLinks = res.footerCompanyLinks;
      console.log(this.footerCompanyLinks)
      this.footerResourcesLinks = res.footerResourcesLinks;
      this.footerBottomLinks = res.footerBottomLinks;
      this.footerBottomFirstLinks = res.footerBottomLinks.slice(0,3);
      this.footerBottomSecondLinks = res.footerBottomLinks.slice(3);
    })

    this.footerCompanyLinks = this.apiCallService.getFooterLinksData().footerCompanyLinks

    this.footerResourcesLinks = this.apiCallService.getFooterLinksData().footerResourcesLinks;
    this.footerBottomLinks = this.apiCallService.getFooterLinksData().footerBottomLinks;
    this.footerBottomFirstLinks = this.apiCallService.getFooterLinksData().footerBottomLinks.slice(0,3);
    this.footerBottomSecondLinks = this.apiCallService.getFooterLinksData().footerBottomLinks.slice(3);



    if (sessionStorage.getItem('butler') == 'true') {
      this.butlerApp = true;
      this.bgColor = '#FFFFFF';
      this.textAlign = 'center';
    } else {
      this.butlerApp = false;
      this.bgColor = '#dad9d7';
    }
  }

  selectCompanyLink(url: string): void {
    // window.location.href = url
    window.open(url, "_blank")
  }

  checkForSmbUser() {
    if (sessionStorage.getItem('isSMBUser') == 'true') {
      this.isSMBUser = true;      
    } else {
      this.isSMBUser = false;
    }
  }
}